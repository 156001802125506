<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="data.regionCode"
        :options="areaOptions"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shops"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item label="设备类型">
      <el-select v-model="data.deviceType" placeholder="请选择" clearable>
        <el-option v-for="item in devices" :key="item.code" :label="item.label" :value="item.code" />
      </el-select>
    </el-form-item>
    <el-form-item label="主/副收银">
      <el-select v-model="data.cashierType" placeholder="请选择" clearable>
        <el-option v-for="item in cashs" :key="item.code" :label="item.label" :value="item.code" />
      </el-select>
    </el-form-item>
    <el-form-item label="收银版本号">
      <el-input placeholder="请输入收银版本号" clearable v-model="data.version"></el-input>
    </el-form-item>
    <el-form-item label="MAC地址">
      <el-input placeholder="请输入MAC地址" clearable v-model="data.mac"></el-input>
    </el-form-item>
    <el-form-item label="设备状态">
      <el-select v-model="data.deviceState" placeholder="请选择" clearable>
        <el-option v-for="item in deviceStatus" :key="item.code" :label="item.label" :value="item.code" />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column show-overflow-tooltip prop="storeCode" label="门店编码" align="center" />
    <el-table-column show-overflow-tooltip prop="storeName" label="门店名称" align="center" />
    <el-table-column show-overflow-tooltip prop="deviceTypeDesc" label="设备类型" align="center" />
    <el-table-column show-overflow-tooltip prop="mac" label="MAC地址" align="center" width="200" />
    <el-table-column show-overflow-tooltip prop="cashierTypeDesc" label="主/副收银" align="center" width="200" />
    <el-table-column show-overflow-tooltip prop="version" label="版本号" align="center" />
    <el-table-column show-overflow-tooltip prop="reportingNumber" label="上报次数" align="center" />
    <el-table-column show-overflow-tooltip prop="reportingTime" label="最近上报时间" align="center" width="200" />
    <el-table-column show-overflow-tooltip prop="reportingStoreName" label="最近上报门店" align="center" width="200" />
    <el-table-column show-overflow-tooltip prop="deviceStateDesc" label="设备状态" align="center"> </el-table-column>
    <el-table-column show-overflow-tooltip prop="telephone" label="手机号" align="center" width="200">
    </el-table-column>
    <el-table-column label="操作" width="300" align="center" fixed="right">
      <template #default="scope">
        <el-button
          type="text"
          @click="specOperation(1, scope.row)"
          v-if="data.authMenus.untie && scope.row.deviceState == 1"
          >解绑</el-button
        >
        <el-button
          type="text"
          @click="specOperation(3, scope.row)"
          v-if="data.authMenus.register && scope.row.deviceState == 0"
          >注册</el-button
        >
        <el-button
          type="text"
          @click="specOperation(2, scope.row)"
          v-if="data.authMenus.updateCashier && scope.row.cashierType == 1 && scope.row.deviceType != 2"
          >设置为主收银</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import commonApi from '@/api'
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/index'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const casRef = ref()
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

//设备类型
const devices = ref([
  {
    label: '机顶盒',
    code: 0,
  },
  {
    label: '一体机',
    code: 1,
  },
  {
    label: 'PAD',
    code: 2,
  },
])
//收银机
const cashs = ref([
  {
    label: '主收银',
    code: 0,
  },
  {
    label: '副收银',
    code: 1,
  },
])
//设备状态
const deviceStatus = ref([
  {
    label: '未注册',
    code: 0,
  },
  {
    label: '已注册',
    code: 1,
  },
])

const data = reactive({
  authMenus: {},
  storeCode: [],
  regionCode: [],
  areaCode: [],
  tableData: [],
  shops: [],
  cashierType: '',
  deviceState: '',
  deviceType: '',
  mac: '',
  version: '',
  pageSize: 10,
  currentPage: 1,
  total: 0,
  loading: false,
  loadingText: '正在火速加载数据...',
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
  getAreas()
})

const getShops = () => {
  let params = {}
  if (data.areaCode.length > 0) {
    params.withInOrgans = data.areaCode
  }
  commonApi.getNewShop(params).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.storeCode = []
  data.regionCode = []
  data.areaCode = []
  data.cashierType = ''
  data.deviceState = ''
  data.deviceType = ''
  data.mac = ''
  data.version = ''
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    storeCode: data.storeCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    regionCode: data.areaCode,
    cashierType: data.cashierType,
    deviceState: data.deviceState,
    deviceType: data.deviceType,
    mac: data.mac,
    version: data.version,
  }

  API.queryStoreMacPage(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data?.records ?? []
        data.total = res.data?.totalRecords ?? 0
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//操作 type 1: 解绑 2：设置为主收银 3：注册
const specOperation = async (type, data) => {
  const user = JSON.parse(localStorage.getItem('vuex'))
  let username = `${user.user.userInfo.userSaasUserVO.userName}(${user.user.userInfo.userSaasUserVO.cellPhone})`
  let param = {
    id: data.id,
  }
  let ret = {}
  switch (type) {
    case 1:
      param.deviceState = 0
      param.untyingName = username
      ret = await API.updateByDeviceState(param)
      break
    case 2:
      ElMessageBox.confirm(`确认将${data.mac}设备设置为${data.storeName}的主收银吗？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        param.cashierType = 0
        param.updateUser = username
        param.storeCode = data.storeCode

        ret = await API.updateByCashierType(param)
        if (ret.code != 200) {
          ElMessage.error(ret.msg)
        } else {
          ElMessage.success('操作成功')
          queryLists()
        }
      })
      break
    case 3:
      param.deviceState = 1
      param.registerUser = username
      ret = await API.updateByDeviceState(param)
      break
  }
  if (ret.code != 200) {
    ElMessage.error(ret.msg)
  } else {
    ElMessage.success('操作成功')
    queryLists()
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
